import React, { useState, useEffect } from "react";
import '../../styles/components/travels/travelCard.scss';
import { CgProfile } from 'react-icons/cg'
import { Button, Container } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { useAppLoader } from "../../_common/hooks/common/appLoaderHook";
import { useTravelsApi } from "../../_common/hooks/api/travelsApiHook";
import moment from "moment";
import Header from "../header";
import LeftPanel from "../leftPanel";
import { IMAGE_ASSETS } from "../../assets";
import KinDetails from "../../pages/update/kinDetails";
import { css } from '@emotion/css';
import { FLAGS_CDN_URL } from "../../_common/constants";

export default function TravelCard() {

    const { id } = useParams()
    const travelsApi = useTravelsApi()
    const navigate = useNavigate()
    const { showLoader, hideLoader } = useAppLoader()

    const [travel, setTravel] = useState<string>("")
    const [details, setDetails] = useState<any>(null)

    const getTravelsDeatails = () => {
        showLoader()
        travelsApi.getTravelsDetails({ travel: travel },
            (message: string, resp: any) => {
                setDetails(resp)
                hideLoader()
            }, (message: any) => {
                console.log({ message })
                hideLoader()
            })
    }

    useEffect(() => {
        if (id) {
            setTravel(id)
        }
    }, [id])

    useEffect(() => {
        if (travel != "") {
            getTravelsDeatails()
        }
    }, [travel])

    return (
        <React.Fragment>
            <Header />
            <Container>
                <div className="wrapper-left-right-panel">
                    <LeftPanel img={IMAGE_ASSETS.travelSuitcases} text={"If you need Consular assistance whilst abroad, you can contact the Consular Section of the South African Diplomatic Mission in the country directly."} />
                    <div className='main-div'>
                        <div className="heading">
                            <div className="heading-left">
                                <CgProfile className="usericon" />
                                <div className="title">
                                    <p className="name">{details?.LodgeName}</p>
                                    <p className="location">{details?.City}, {details?.Country}</p>
                                </div>
                            </div>
                            <div className="heading-right">
                            </div>
                        </div>
                        {/* <div className="image-div">
                            {details?.Attachments && details?.Attachments.length > 0 ?
                                <Carousel className="details-carousal">
                                    {details?.Attachments.map((item: any, i: number) => (
                                        <Carousel.Item key={i}>
                                            {/* <img
                                                className="d-block w-100"
                                                src="https://images.unsplash.com/photo-1434725039720-aaad6dd32dfe?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1042&q=80"
                                                alt="First slide"
                                            /> */}

                        {/* </Carousel.Item>
                                    ))}
                                </Carousel>
                                : null
                            }
                        </div> */}
                        <div className={css({display: "flex", justifyContent: 'center' })}>
                            {
                               details?.ISOCode ?
                               <img src={ `${FLAGS_CDN_URL}/w640/${details?.ISOCode.toLowerCase()}.png`} alt=""></img>:
                               <img src="/assets/img/logo.svg"  max-width= "100%" max-height="100%" alt=""></img>

                            }
                          
                        </div>

                        <div className="description">
                            <div>
                                <p className="description-text">{details?.Description}</p>
                                <p className="location">{details?.City}, {details?.Country}</p>
                            </div>
                        </div>
                        <br />
                        <div className="details">
                            <p>Country: {details?.Country}</p>
                            <p>City: {details?.City}</p>
                            <p>Departue: {moment(details?.DepartureDate).format('DD/MM/YYYY')}</p>
                            <p>Return: {moment(details?.ReturnDate).format('DD/MM/YYYY')}</p>
                            <p>Residence: {details?.LodgeName}</p>
                        </div>
                        <div className="button-div">
                            <Button onClick={() => navigate(`/travels`)}>Back</Button>
                        </div>
                    </div>
                </div>
            </Container>
        </React.Fragment>

    )
}