import React from "react";
import { Container } from "react-bootstrap";
import "../../styles/components/cases/noCases.scss";
import { css } from "@emotion/css";
import { useNavigate } from "react-router-dom";

export default function NoCases() {
    const navigate = useNavigate();

    return (
        <React.Fragment>

            <div className="no-cases w-100">
                <Container>
                    <div className="wrapper-left-right-panelc w-100">
                        <div className="w-100">
                            <img src="/assets/img/undraw_questions.svg" alt="NOCASE" />
                            <h1 className="text2">You do not have any cases currently</h1>
                            <h3>You can easily create new cases, click the button below get started</h3>
                            <button className="btn btn-primary w-100" onClick={() => navigate("/create-case")}>Create case</button>
                            <p className={css({ marginTop: 16, color: '#8181A5', fontSize: '0.75rem', lineHeight: '1rem' })}>
                                By submitting a case you agree to abide by the terms or DIRCO’s service delivery charter
                            </p>
                            {/* <ConsentCreateCaseModal show={show} onHide={()=>setShow(false)}/>    */}
                        </div>
                    </div>
                </Container>
            </div>
        </React.Fragment>
    )
}