import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import Header from "../components/header";
import NoCases from "../components/cases/noCases";
import CasesList from "../components/cases/casesList";
import { useAppLoader } from "../_common/hooks/common/appLoaderHook";
import { useCasesApi } from "../_common/hooks/api/casesApiHook";
import { useUserSelector } from "../_common/hooks/selectors/userSelector";
import LeftPanel from "../components/leftPanel";

export default function Cases() {

    const caseApi = useCasesApi()
    const { showLoader, hideLoader } = useAppLoader()
    const user: any = useUserSelector()

    const [cases, setCases] = useState<any>(null)
    const [activeCases, setActiveCases] = useState<any>(null)
    const [resolvedCases, setResolvedCases] = useState<any>(null)
    const [loading, setLoading] = useState<boolean>(false)


    const getCases = () => {
        showLoader()
        setLoading(true)
        caseApi.getCases({
            contact: user.contactid,
        },
            (message: string, resp: any) => {
                hideLoader()
                setLoading(false)
                if (Array.isArray(resp)) {
                    setCases(resp)
                    setActiveCases(resp.filter(el => el.statecode
                        === 0))
                    setResolvedCases(resp.filter(el => el.statecode
                        === 1))
                }
                else {
                    setActiveCases([])
                    setCases([])
                }
            }, (message: string) => {
                hideLoader()
                setLoading(false)
                console.log(message);
            })
    }

    useEffect(() => {
        if (cases == null && user) {
            getCases()
        }
    }, [cases, user])

    return (
        <React.Fragment>
            <Header active={"cases"}/>
            {loading ?
                null
                :
                <Container fluid>
                    <div className="wrapper-left-right-panel">
                        <LeftPanel img={"/assets/img/SideSlide.jpg"} text={"If you need Consular assistance whilst abroad, you can contact the Consular Section of the South African Diplomatic Mission in the country directly."}/>
                        {cases && cases.length > 0 ? <CasesList cases={cases} activeCases={activeCases} resolvedCases={resolvedCases}/> : <NoCases/>}
                    </div>
                    
                    
                </Container>
            }
        </React.Fragment>
    )
}




