import { css } from '@emotion/css';
import React, { forwardRef } from 'react';
import { ListGroup } from 'react-bootstrap';
import { FaPlusCircle, FaTimes } from 'react-icons/fa';

interface FileInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  onDeleteFile: (file: unknown) => void;
  label?: string;
  files: any[];
}

export const FileInput = forwardRef<unknown, FileInputProps>(
  ({ label = 'Attachments', files, onDeleteFile, ...restOfProps }, ref) => {
    return (
      <div
        className={css({
          // border: '2px solid #356b1a',
          borderRadius: 8,
          position: 'relative',
          top: 8,
        })}
      >
        <ListGroup>
          {files.map((file: any, i: any) => {
            return (
              <ListGroup.Item>
                <div className={css({ display: 'flex' })}>
                  <FaTimes
                    style={{ color: 'red' }}
                    className="me-2"
                    onClick={() => {
                      onDeleteFile(file);
                    }}
                  />
                  <small
                    className={css({
                      width: '100%',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                    })}
                  >
                    {file.FileName}
                  </small>
                </div>
              </ListGroup.Item>
            );
          })}
          <ListGroup.Item action>
            <label>
              <div
                className={css({
                  display: 'inline-flex',
                  whiteSpace: 'nowrap',
                  alignItems: 'center',
                })}
              >
                <FaPlusCircle />
                &nbsp;<strong>Add Attachment...</strong>
              </div>
              <input
                ref={ref as any}
                className={css({
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                  opacity: 0,
                })}
                type="file"
                {...restOfProps}
              />
            </label>
          </ListGroup.Item>
        </ListGroup>
        <legend
          className={css({
            background: 'white',
            fontSize: '0.75rem',
            // color: '#356b1a',
            marginLeft: 10,
            marginRight: 10,
            paddingLeft: 10,
            paddingRight: 10,
            display: 'inline-block',
            width: 'fit-content',
            position: 'absolute',
            zIndex: 1020,
            top: -8,
          })}
        >
          {label}
        </legend>
      </div>
    );
  }
);
